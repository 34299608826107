<template>
  <div
    class="recipe__list-item"
    :class="'categoria-' + item.item.categoria_ricetta[0]"
  >
    <div class="recipe__list-inner">
      <div class="touch-gradient">
        <div class="image">
          <router-link
            :to="{ name: 'Recipe Details Stella Roux', params: { id: id } }"
          >
            <img :src="item.image" />
          </router-link>
        </div>
        <div class="clearfix"></div>
        <!--<div v-if="item.item.categoria_ricetta[0] != 29">
          <div class="icons">
            <div class="heart posizione1" @click="_like">
              {{ item.item.nr_like[0] }}
              <img v-if="liked == false" src="../../assets/icons/likevw.svg" />
              <img v-if="liked == true" src="../../assets/icons/likepw.svg" />
            </div>
            <div class="heart posizioneUltima" @click="_bookmark">
              {{ item.item.nr_bookmark[0] }}
              <img
                v-if="bookmarked() == true"
                src="../../assets/icons/bookmarkpw.svg"
              />
              <img
                v-if="bookmarked() == false"
                src="../../assets/icons/bookmarkvw.svg"
              />
            </div>
          </div>
        </div>
        -->
        <div class="clearfix"></div>
        <router-link
          :to="{ name: 'Recipe Details Stella Roux', params: { id: id } }"
        >
          <div class="title" v-html="title"></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import myMixin from '@/components/_mixin/myMixin';

export default {
  name: 'RecipeCardStellaRoux',
  mixins: [myMixin],
  props: ['image', 'title', 'id', 'item'],
  methods: {
    async _like() {
      const r = await this.like();
      this.item.item.nr_like[0] = r.nr;
    },
    async _bookmark() {
      const r = await this.bookmark();
      this.item.item.nr_bookmark[0] = r.nr;
    },
  },
  computed: {
    _liked() {
      return this.liked();
    },
    liked() {
      let trovato = -1;

      if (!this.$store.getters['auth/isLoggedIn']) return false;
      this.user_data.like.ricetta.forEach((item, index) => {
        if (this.id == parseInt(item)) trovato = index;
      });

      if (trovato > -1) return true;
      return false;
    },
  },
};
</script>

<style scoped>
.recipe-card2 {
  display: flex;
  justify-content: center;
  align-items: center;
  widows: 100%;
  height: 95px;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  margin-bottom: 30px;
  border-radius: 9999px;
  overflow: hidden;
}

.recipe-card2 .inner:after {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  height: 98.5%;
  width: 100%;
  border-radius: 16px;
  background: linear-gradient(
    315deg,
    rgba(0, 119, 255, 0.4) 0%,
    rgba(255, 0, 62, 0.9) 100%
  );
  mix-blend-mode: multiply;
  transition: 0.3s;
  transform: translateZ(0.0001deg);
  backface-visibility: hidden;
  will-change: opacity;
  border-radius: 9999px;
  overflow: hidden;
}

.recipe-card__category {
  position: absolute;
  margin: 0;
  color: white;
  text-transform: uppercase;
  transition: 0.5s;
  z-index: 9999;
}

.recipe-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  border-radius: 16px;
  overflow: hidden;
}

@media all and (max-width: 768px) {
  h3 {
    font-size: 14px !important;
  }
}

.icons {
  font-size: 14px !important;
}

.recipe__list-item {
  width: 33%;
  max-width: 33%;
  padding: 0;
  text-align: left;
  float: left;
}

.recipe__list-inner {
  display: block;
  font-size: 1.25rem;
  padding: 5px;
  border-bottom: 1px solid #eee;
  font-weight: 600;
  margin-bottom: 10px;
}

.recipe__list-inner img {
  border: 1px solid #ccc;
  box-shadow: 2px 2px #ccc;
}

.recipe__list-item a {
  text-decoration: none;
}

.recipe__list-inner .title {
  font-size: 0.8rem;
  color: #fff;
  height: 50px;
  overflow: hidden;
}

.recipe__list-item img {
  width: 100%;
  height: 120px;
  object-fit: cover;
}

.icons {
  border: none;
}

.icons .posizioneUltima {
  float: right;
}

.icons img {
  width: 15px;
  height: 15px;
  max-width: 15px;
  max-height: 15px;
  border: none;
  box-shadow: none;
  margin-right: 4px;
  margin-left: 2px;
  margin-bottom: 6px;
}
.icons div {
  float: left;
  border: none;
  display: flex;
}

.categoria-29 .recipe__list-inner {
  border-bottom: none;
}
.categoria-29 .title {
  height: 50px;
  overflow: hidden;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  margin-top: 6px;
}

.machines-wrapper {
  background-color: white;
}
</style>
