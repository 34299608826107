<template>
  <div id="stellaroux">
    <div class="recipes container" v-if="loading">
      <strong>{{ $gettext('Cerco le ricette..') }}</strong>
    </div>

    <div class="recipes container" v-if="!loading">
      <div id="intro">
        <div id="img"><img src="../../assets/img/stella_roux_intro.png" /></div>
        <div id="description">
          <p>
            <strong>Stella Roux</strong>
            {{ $gettext('è la protagonista di una serie di racconti che mescolano fantasia a personaggi e avvenimenti reali, attraverso le sue avventure riscopre l\'origine di alcuni fra i cocktail più celebri nel panorama del bere miscelato.') }}
          </p>
          <p>
            <strong>{{ $gettext('Le vicende di Stella Roux') }} </strong>
            {{ $gettext('si svolgono nel futuro, dove una multinazionale ha il controllo di tutto il cibo e le bevande del mercato e si impegna per distruggere qualsiasi tentativo di preservare le ricette originali dei cocktail più famosi del passato, per mantenere le persone senza gusto e possibilità di stare insieme fra loro.') }}
          </p>
          <p>
            {{ $gettext('Stella grazie a una') }}
            <strong>{{ $gettext('macchina del tempo') }}</strong>
            {{ $gettext('riesce a viaggiare a ritroso per recuperare queste preziose ricette, incontrando gli autori o scoprendo l\'epoca in cui queste prendon forma, per reinterpretarle in modo innovativo con l’aiuto della tecnologia Sirman.') }}
          </p>
          <div id="scopri">
            <a href="https://stellaroux.sirman.com/it-it/a-caccia-di-cocktails-perduti" target="_blank">{{ $gettext('Scopri tutte le sue avventure!') }}</a>
          </div>
        </div>
      </div>
      <div id="intro2">
        <h2>{{ $gettext('I cocktail di "Stella Roux"')}}</h2>
      </div>

      <recipe-card-stella-roux
        v-for="item in listing"
        :key="item.id"
        :title="item.title"
        :image="item.image"
        :id="item.id"
        :item="item"
      ></recipe-card-stella-roux>
      <div class="clearfix"></div>
    </div>
    <div class="clearfix"></div>
  </div>
  <div class="clearfix"></div>
</template>

<script>
import RecipeCardStellaRoux from '@/components/recipe/RecipeCardStellaRoux.vue';
import recipeCardUsers from '@/components/recipe/RecipeCardUsers.vue';
import MyMixin from '@/components/_mixin/myMixin';

export default {
  name: 'recipe-listing',
  components: { RecipeCardStellaRoux, recipeCardUsers, MyMixin },
  mixins: [MyMixin],
  data() {
    return {
      listing: [],
      filterBy: '',
      filterCategory: [],
      loading: false,
      userType: 0,
      image: '',
    };
  },

  methods: {
    loadRecipes() {
      this.loading = true;
      let url = this.api.getRecipeListing + '&categoria_ricetta=29&' + this.$store.getters['auth/getLanguageRest'];

      this.listing = [];
      this.axios
        .get(url)
        .then((response) => {
          const listing = [];

          response.data.forEach((item, index) => {
            listing.push({
              authorName: item.author_name,
              authorId: item.author,
              id: item.id,
              title: item.title.rendered,
              image: '/images/placeholder-768x768.png',
              categoria: this.getCategoryName(item.categoria_ricetta[0]),
              item: item,
            });

            this.loadImageURL(item.featured_media, index);
          });

          this.listing = listing;
          this.loading = false;
        })
        .catch((error) => {
          console.log('Error: ' + error);
        });
    },
    loadCategories() {
      this.axios
        .get(this.api.categoriesRecipe + '?per_page=100&' + this.$store.getters['auth/getLanguageRest'])
        .then((res) => {
          const categories = [];

          for (const data of res.data) {
            if (data.id == 29) {
              this.image = data.imgSrcFull;
            }

            continue;
          }

          this.filterCategory = categories;
        })
        .catch((err) => {
          console.log('Error: ' + err);
        });
    },
    getCategoryName(id) {
      let name = '';
      this.filterCategory.forEach((item) => {
        if (item.id == id) name = item.name;
      });
      return name;
    },
    async loadImageURL(idM, idListing) {
      let mediaUrl = this.api.getMediaUrl + idM;

      await fetch(mediaUrl)
        .then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            console.log('Error in loading image');
          }
        })
        .then((res) => {
          this.listing[idListing].image =
            res.media_details.sizes.thumbnail.source_url;
        })
        .catch((err) => {
          console.log('Error: ' + err);
        });
    },
  },

  mounted() {
    this.loadCategories();
    this.loadRecipes();
  },
};
</script>

<style scoped>
img {
  width: 100%;
}

h1 {
  margin: 20px;
  padding: 0;
  font-size: 2rem;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-weight: 600;
}

h2 {
  line-height: 1.25;
}

.card {
  position: relative;
}

.card h3 {
  margin: 0;
}

.recipes {
  max-width: 1170px;
  width: 90%;
  margin: 0 auto;
  margin: 10px auto;
}

#filter {
  margin-bottom: 20px;
  width: 100%;
}
#filter select {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px #ccc solid;
}

#description {
  text-align: justify;
  margin-top: 10px;
}

#stellaroux {
  margin-top: -20px;
  background-color: var(--var-primary-color);
  color: white;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 120%;
  padding-bottom: 20px;
}

#scopri {
  background-color: black;
  color: white;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

#scopri a {
  color: white;
  font-size: 1.5rem;
}

#scopri a:visited {
  text-decoration: none;
  color: white;
}

main {
  padding-top: 0 !important;
}

#intro2 h2 {
  margin-top: 40px;
  color: white !important;
  font-size: 2rem !important;
  font-weight: 700;
}
</style>
